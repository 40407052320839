import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, useTheme, Box, Flex } from '@chakra-ui/react';
import ArchiveGroup, { ArchiveGroupProps } from 'src/components/ArchiveGroup';

export interface PureArchiveProps {
  allBlogPost: GatsbyTypes.ArchievePageQuery['allBlogPost'];
}

export function PureArchive({ allBlogPost }: PureArchiveProps) {
  const blogPosts = [...allBlogPost.group].sort(
    (a, b) =>
      +new Date(b.fieldValue as string) - +new Date(a.fieldValue as string),
  );

  const archiveGroups: ArchiveGroupProps[] = blogPosts.map(group => ({
    month: new Date(group.fieldValue!),
    items: group.nodes.map(post => ({
      id: post.id,
      title: post.title,
      date: post.date,
      slug: post.slug,
      category: post.categoryLabel!,
    })).sort((a, b) =>
      +new Date(b.date as string) - +new Date(a.date as string)
    ),
  }));

  const theme = useTheme();

  return (
    <Container
      maxW="container.xl"
      display="flex"
      flexDirection="column"
      px={4}
      minH={['auto', `calc(100vh - ${theme.sizes['14']})`]}
    >
      <Flex flexDirection={['column', 'row']}>
        <Box flex="3" />
        <Box flex="7" py={8} borderLeftWidth="1px" borderLeftColor="gray.200" />
      </Flex>
      {archiveGroups.map(group => (
        <ArchiveGroup
          key={group.month.getTime()}
          month={group.month}
          items={group.items}
        />
      ))}
      <Flex flex="1" flexDirection={['column', 'row']}>
        <Box flex="3" />
        <Box flex="7" py={8} borderLeftWidth="1px" borderLeftColor="gray.200" />
      </Flex>
    </Container>
  );
}

export default function Archive() {
  const { allBlogPost } = useStaticQuery<GatsbyTypes.ArchivePageQuery>(
    graphql`
      query ArchivePage {
        allBlogPost {
          group(field: dateFields___month) {
            nodes {
              id
              title
              categoryLabel
              slug
              date(formatString: "YYYY/MM/DD")
            }
            fieldValue
          }
        }
      }
    `,
  );

  return <PureArchive allBlogPost={allBlogPost} />;
}
