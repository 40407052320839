import React from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  Link,
  useTheme,
  useColorModeValue,
} from '@chakra-ui/react';

interface ArchiveItem {
  id: string;
  title: string;
  date: string;
  slug: string;
  category: string;
}

export interface ArchiveGroupProps {
  month: Date;
  items: ArchiveItem[];
}

export default function ArchiveGroup({ month, items }: ArchiveGroupProps) {
  const theme = useTheme();
  
  return (
    <Flex flexDirection={['column', 'row']}>
      <Box flex="3" py={8} textAlign={['left', 'center']}>
        <Box display="inline-block">
          <Heading layerStyle="nowText" size="xl" py={2}>
            {month.getMonth() + 1}月
          </Heading>
          <Text layerStyle="nowText">{month.getFullYear()}年</Text>
        </Box>
        <Box as="span" color="gray.500" verticalAlign="100%" ml={4}>
          {items.length} 篇
        </Box>
      </Box>
      <Box
        flex="7"
        py={8}
        my={[4, 0]}
        borderLeftWidth="1px"
        borderLeftColor="gray.200"
      >
        {items.map(item => (
          <Box key={item.id} position="relative" ml={16} mb={8}>
            <Text
              as="span"
              className="iconfont icon-event"
              position="absolute"
              left={`calc(-${theme.space[16]} - ${theme.fontSizes['3xl']}/2)`}
              color="gray.500"
              bgColor={useColorModeValue('white', 'gray.800')}
              fontSize="3xl"
            />
            <Text as="span" color="gray.500">
              {item.date}
            </Text>
            <Text as="span" ml={4} color="blue.500">
              {item.category}
            </Text>
            <Text mt={2}>
              <Link
                href={item.slug}
                variant="now"
                colorScheme="now"
                fontSize="lg"
              >
                {item.title}
              </Link>
            </Text>
          </Box>
        ))}
      </Box>
    </Flex>
  );
}
